import React, { useState } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import {
  Grid,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Divider,
  Snackbar,
  Modal,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { useSelector } from "react-redux"
import Axios from "axios"
import { BASE_URL } from "../../config/api"
import { Alert, AlertTitle } from "@material-ui/lab"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(15),
    },
  },
  formWrapper: {
    marginTop: theme.spacing(5),
  },
  formSpacing: {
    marginTop: theme.spacing(4),
  },
  formButton: {
    marginTop: theme.spacing(5),
  },
  card: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  selectIcon: {
    color: theme.palette.primary.main,
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
    transition: "all 0.3s ease",
  },
  arrowContrast: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
  modal : {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  qrcode: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
  }

}))

const Textfield = ({
  title,
  description,
  defaultValue,
  customImage,
  locale,
  cardVariant,
}) => {
  const data = useStaticQuery(graphql`
    query contactQuery {
      allContentfulLandingPageModel {
        edges {
          node {
            node_locale
            queryDescription {
              internal {
                content
              }
            }
            queryTopics
            querySectionImage {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            hateContactFormTitle
            hateContactFormSubTitle
            hateContactFormLogos {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            hateContactFormLogosId
          }
        }
      }
      allContentfulAboutPageModel {
        edges {
          node {
            aboutTeamCards {
              wechatQrcode {
                fluid {
                  ...GatsbyContentfulFluid
                }
                title
              }
            }
          }
        }
      }
    }
  `)
  // const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)

  const localData = data.allContentfulLandingPageModel.edges.filter(
    ({ node }) => node.node_locale === locale
  )

  const {
    querySectionImage,
    queryDescription,
    queryTopics,
    hateContactFormTitle,
    hateContactFormSubTitle,
    hateContactFormLogos,
    hateContactFormLogosId,
  } = localData[0].node
  const { wechatQrcode } = data.allContentfulAboutPageModel.edges[0].node.aboutTeamCards[0]

  const classes = useStyles()
  const [topic, setTopic] = useState(defaultValue ? defaultValue : "")
  const [fullName, setFullName] = useState(defaultValue ? defaultValue : "")
  const [email, setEmail] = useState(defaultValue ? defaultValue : "")
  const [message, setMessage] = useState(defaultValue ? defaultValue : "")
  const [isOpen, setIsOpen] = useState(false)
  const [alert, setAlert] = useState({
    severity: "error",
    message: "Something went wrong",
  })
  const [open, setOpen] = useState(false)

  const setState = {
    fullName: fullname => setFullName(fullname),
    email: email => setEmail(email),
    message: message => setMessage(message),
    topic: topic => setTopic(topic),
  }
  const handleChange = event => {
    setState[event.target.name](event.target.value)
  }

  // Sew together logo ID and logo image arrays in to array of objects.
  // Content model needs to be fixed to provide this directly
  const logosData = hateContactFormLogosId.map((logoId, index) => {
    return { id: logoId, logo: hateContactFormLogos[index].fluid }
  })

  const getEmailBody = () => `
    <div>
      <p>
        <strong>Name:</strong> ${fullName}
      </p>
      <p>
        <strong>Email:</strong> ${email}
      </p>
      <p>
        <strong>Message:</strong> ${message}
      </p>
  `
  const handleSend = event => {
    event.preventDefault()
    const emailBody = getEmailBody()
    const body = {
      subject: topic,
      html: emailBody,
    }
    try {
      Axios.post(BASE_URL + "/email", body)
        .then(response => {
          setIsOpen(true)
          setAlert({ severity: "success", message: response.data.message })
        })
        .catch(test => console.dir(test))
    } catch (err) {
      setAlert({ severity: "error", message: err })
      setIsOpen(true)
      console.log(err)
    }
  }

  const closeAlert = () => {
    setIsOpen(false)
    setAlert({
      severity: "error",
      message: "Something went wrong",
    })
  }

  const handleClick = item => {
    if (item.includes("@")) {
      window.open("mailto:" + item)
    } else if (item.includes("+")) {
      item = item.replace(/\D/g, "")
      window.open("https://wa.me/" + item)
    } else {
      handleOpen()
    }    
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Container className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert severity={alert.severity}>
          <AlertTitle>{alert.severity.toUpperCase()}</AlertTitle>
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container wrap="wrap-reverse">
        <Grid item xs={12} md={6}>
          <Img
            fluid={customImage ? customImage.fluid : querySectionImage?.fluid}
          />
        </Grid>
        <Grid item md={1} />
        <Grid item md={4}>
          <Typography variant="h2">
            {title ? title : translations?.sayHello}
          </Typography>
          <Typography variant="subtitle1">
            {description ? description : queryDescription.internal.content}
          </Typography>
          <div className={classes.formWrapper}>
            <form onSubmit={handleSend}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel
                  required
                  id="topic-label"
                  htmlFor="topic-select"
                  shrink
                >
                  {translations?.topic}
                </InputLabel>
                <Select
                  name="topic"
                  onChange={handleChange}
                  value={topic}
                  displayEmpty
                  classes={{
                    icon: classes.selectIcon,
                  }}
                >
                  <MenuItem value="" disabled>
                    Select a topic
                  </MenuItem>
                  {queryTopics.map(topic => (
                    <MenuItem key={topic} value={topic}>
                      {topic}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                name="fullName"
                onChange={handleChange}
                className={classes.formSpacing}
                label={translations?.fullName}
                placeholder={translations?.typeName}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                type="email"
                name="email"
                onChange={handleChange}
                className={classes.formSpacing}
                label={translations?.emailAddress}
                placeholder={translations?.typeEmail}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                name="message"
                onChange={handleChange}
                className={classes.formSpacing}
                label={translations?.message}
                placeholder={translations?.typeMessage}
                fullWidth
                InputLabelProps={{ shrink: true }}
                multiline
              />
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.formButton}
                endIcon={
                  <div className={classes.arrow}>
                    <ArrowForwardIcon />
                  </div>
                }
              >
                {translations?.sendMessage}
              </Button>
            </form>
          </div>
          <Card
            variant={cardVariant ? cardVariant : "outlined"}
            className={classes.card}
          >
            <CardContent className={classes.cardContent}>
              <Typography variant="h6">{hateContactFormTitle}</Typography>
              <Typography variant="subtitle1" style={{ paddingTop: "4px" }}>
                {hateContactFormSubTitle}
              </Typography>
            </CardContent>
            <CardActions>
              <Box clone width={1}>
                <List>
                  {logosData.map(({ id, logo }, index) => (
                    <div onClick={() => handleClick(id)}>
                      {index > 0 ? <Divider /> : null}
                      <ListItem key={id} button>
                        <Box clone width={20} mr={2}>
                          <Img fluid={logo} />
                        </Box>
                        <ListItemText
                          primary={id}
                          primaryTypographyProps={{ variant: "body2" }}
                        />
                        <ListItemIcon>
                          <div className={classes.arrowContrast}>
                            <ArrowForwardIcon />
                          </div>
                        </ListItemIcon>
                      </ListItem>
                    </div>
                  ))}
                </List>
              </Box>
            </CardActions>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.modal}>
                <img
                  src={wechatQrcode?.fluid?.src}
                  alt="wechat"
                  className={classes.qrcode}
                />
              </div>
            </Modal>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Textfield
